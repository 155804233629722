import React from "react"
import { graphql } from "gatsby"
import ImageHandler from '../components/imageHandler'
import BlockContent from '../components/blockContent'
import SEO from '../components/seo'

export default (props) => {
  const data = props.data.sanityPage
  const { pageContext } = props
  const slug = pageContext ? pageContext.slug : ""
  let pageClassName
  if (slug) {
    slug.current === '/' ? 
      pageClassName = `is-page-home` :
      pageClassName = `is-page-${slug.current}`
  } else {
    pageClassName = `is-page-404`
  }

  return (
    <div className={pageClassName}>
      <SEO
        title={data.title}
        description="Valérie Chenain Pilate Toulouse"
        image={data.featuredImage ? data.featuredImage.asset ? data.featuredImage.asset.fluid.src : null : null}
        url={slug.current !== '/' ? props.data.site.siteMetadata.siteUrl + `/` + slug.current : props.data.site.siteMetadata.siteUrl}
      />
      {(data.featuredImage && data.featuredImage.asset ) ? (
        <header className="section page-header">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-8-desktop is-10-tablet">
                <div className="section-header">
                  <ImageHandler image={data.featuredImage} />
                </div>
              </div>
            </div>
          </div>
        </header>
        ) : null
      }
      <section className="section section-content">
        <div className="container">
          <div className="columns">
            <div className="column is-7-desktop is-offset-4-desktop is-9-tablet is-offset-2-tablet content">
              {data.title && data.displayTitle ? (
                <h1 className="title is-1 section-title">
                  {data.title}
                </h1>
                ) : null
              }
              {data._rawContenu ? (
                  <BlockContent blocks={data._rawContenu} />
                ) : null
              }
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment imageFluidProps on SanityImageFluid {
    src
    srcWebp
    srcSetWebp
    sizes
    srcSet
    aspectRatio
    base64
  }

  query($slug: String!) {
    sanityPage(slug: {current: {eq: $slug}}) {
      title
      displayTitle
      featuredImage {
        asset {
          fluid {
            ...imageFluidProps
          }
        }
      }
      _rawContenu(resolveReferences: {maxDepth: 10})
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
